import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { create } from 'zustand'

export const GlobalLoaderStore = create((set, get) => ({
  loading: false,
  setLoading: (value) => {
    set((state) => {
      return { loading: value };
    })
  },
}))