import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { create } from 'zustand'

export const FormStoreModel = types
  .model("FormStore")
  .props({
    openForms: types.optional(types.frozen(), {}),
  })
  .views((store) => ({
    get getOpenForms() {
      return store.openForms
    },
  }))
  .actions((store) => ({
    setOpenForm(value) {
      store.openForms = { ...store.openForms, ...value }
    },
    deleteForm(value) {
      const newForms = { ...store.openForms }
      delete newForms[value]
      store.openForms = newForms
    },
    closeAllForms() {
      store.openForms = {}
    }
  }))

export const GlobalFormStore = create((set, get) => ({
  openForms: {},
  setOpenForm: (value) => {
    set((state) => {
      return { openForms: { ...state.openForms, ...value } };
    })
  },
  deleteForm: (value) => {
    set((state) => {
      const newForms = { ...state.openForms }
      delete newForms[value]
      return { openForms: newForms };
    })
  },
  closeAllForms: () => {
    set((state) => {
      return { openForms: {} };
    })
  }
}))