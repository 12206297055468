// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert } from "react-native"

import { api } from "../../../services/api"
import { colors, spacing } from "../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput } from "../../../mycomponents/DynamicForm"
import DynamicTable from "../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "./LayoutScreen"
import FormScreen from "./FormScreen"
import ListingScreen from "./ListingScreen"

const EditScreen = observer(function EditScreen(props) {
    const { formStore: { getOpenModals, setOpenModal, getModalHistory, deleteModel } } = useStores()
    let { route } = props;

    let params = route?.params ? route?.params : useParams();

    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();

        let path = window?.location?.pathname;
        route = { name: path.split("/")[1] || 'Items' }

        const params = window.location.search.substring(1).split('&')
        params.map((param) => {
            const [key, value] = param.split('=')
            if (key) {
                queryParams[key] = value;
            }
        })
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    useEffect(() => {
        // navigationFn(route.name + '/New/', {
        //     moduleName: route.name,
        //     isVisible: true,
        //     moduleName: route.name,
        //     afterSaveEvent: 'reloadListing',
        //     ...(props.default_values ? props.default_values : {})
        //   })

        setOpenModal({
            [route.name]: {
                ...queryParams
            }
        })
    }, [])

    return (
        <ListingScreen {...props} />
    )
})

export default EditScreen

const $container = {
    flex: 1,
}