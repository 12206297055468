import React, { useEffect, useState } from "react";
import { View, Card, Text, TouchableOpacity, Button } from "react-native-ui-lib";
import { CustomModal, CustomNumberInput, CustomSelect, CustomSerialNumberTextArea, CustomTable, CustomTextField, Label, SecondaryButton } from "../../mycomponents/DynamicForm";
import { Dimensions, Platform } from "react-native";
import WebView from "react-native-webview";
import { roundTo, roundToUp, roundToDown } from "round-to";
import DynamicTable from "../../mycomponents/DynamicTable";
import { create } from 'zustand'
import { CustomSelectItemForContacts } from "./transaction";
import ListingScreen from "../../screens/MainScreens/CRUD/ListingScreen";

export const inquiryfields = (response, view = false, moduleName = null, OpenModalCustom) => {

    const RecordingComponent = ({ field, useForm, tKey, tIndex }) => {
        const inquiry_form = useForm((state) => state.formObject['inquiry_form']);

        const RecordingUrl = inquiry_form && inquiry_form["RecordingUrl"] ? inquiry_form["RecordingUrl"] : 'https://download.samplelib.com/mp3/sample-3s.mp3';

        return (<View>
            {Platform.OS === 'web' ? <audio style={{ height: 40, width: 350 }} controls src={RecordingUrl} /> : <WebView
                source={{ uri: RecordingUrl }}
                style={{ marginTop: 20, width: 200, height: 200 }}
            />}
        </View>);
    }

    const InvoiceComponent = ({ field, useForm, tKey, tIndex }) => {
        const contact_id = useForm((state) => state.formObject['contact_id']);

        const props = {
            moduleName: 'Invoices',
            hideWrapper: true,
            extraFilters: { contact_id: contact_id ?? null },
        }

        return <View w-100 flex>
            <ListingScreen {...props} />
        </View>
    };

    const QuotationComponent = ({ field, useForm, tKey, tIndex }) => {
        const contact_id = useForm((state) => state.formObject['contact_id']);

        const props = {
            moduleName: 'Quotations',
            hideWrapper: true,
            extraFilters: { contact_id: contact_id ?? null },
        }

        return <View w-100 flex>
            <ListingScreen {...props} />
        </View>
    };

    const FollowUpTasks = ({ field, useForm, tKey, tIndex }) => {
        const _id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'Tasks',
            hideWrapper: true,
            extraFilters: { inquiry_id: _id ?? null },
        }

        return <View w-100 flex>
            <ListingScreen {...props} />
        </View>
    };

    const PaymentsComponent = ({ field, useForm, tKey, tIndex }) => {
        const contact_id = useForm((state) => state.formObject['contact_id']);

        const props = {
            moduleName: 'PaymentReceived',
            hideWrapper: true,
            extraFilters: { contact_id: contact_id ?? null },
        }

        return <View w-100 flex>
            <ListingScreen {...props} />
        </View>
    };

    const formFields = [
        // {
        //     label: 'Contact',
        //     key: 'contact_id',
        //     type: 'select',
        //     placeholder: 'Select the contact',
        //     visible: true,
        //     width: '25%',
        //     options: response.Contacts || [],
        //     tab: 'General',
        //     creatableAction: (props) => {
        //         const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
        //         OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
        //     },
        //     customSelectItem: CustomSelectItemForContacts,
        //     otherSearchKeys: ['phone', 'email'],
        // },
        // {
        //     label: 'Date',
        //     key: 'date',
        //     type: 'date',
        //     placeholder: 'Select the date',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Number',
        //     key: 'number',
        //     type: 'text',
        //     placeholder: 'Enter the number',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        // },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the number',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Phone No',
            key: 'phone',
            type: 'text',
            placeholder: 'Enter the number',
            visible: true,
            width: '25%',
            tab: 'General',
            showCountryCode: true
        },
        {
            label: 'Assigned To',
            key: 'assigned_to_id',
            type: 'select',
            placeholder: 'Enter the number',
            visible: true,
            width: '25%',
            tab: 'General',
            options: response.Employees || [],
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Enter the number',
            visible: true,
            width: '25%',
            tab: 'General',
            options: [
                { label: 'Pending Call', value: 'Pending Call' },
                { label: 'Un Answered', value: 'Un Answered' },
                { label: 'Interested', value: 'Interested' },
                { label: 'Not Interested', value: 'Not Interested' },
                { label: 'Closed', value: 'Closed' },
            ],
            value: 'Pending Call'
        },
        // {
        //     label: 'Recording',
        //     key: 'recording',
        //     type: 'custom',
        //     visible: (connectedObject) => connectedObject.inquiry_form?.RecordingUrl ? true : true,
        //     width: '75%',
        //     component: RecordingComponent,
        //     tab: 'General',
        // },
        {
            label: 'Customer Remarks',
            key: 'customer_remarks',
            type: 'editor',
            placeholder: 'Enter notes',
            visible: true,
            width: '100%',
            tab: 'General'
        },

        // {
        //     type: 'custom',
        //     visible: true,
        //     span: 24,
        //     tab: 'Follow-up Tasks',
        //     component: FollowUpTasks,
        // },

        // {
        //     type: 'custom',
        //     visible: true,
        //     span: 24,
        //     tab: 'Quotations',
        //     component: QuotationComponent,
        // },

        // {
        //     type: 'custom',
        //     visible: true,
        //     span: 24,
        //     tab: 'Work Order',
        //     component: FollowUpTasks,
        // },

        // {
        //     type: 'divider',
        //     heading: 'Service Report',
        //     visible: true,
        //     span: 24,
        //     tab: 'Service Report'
        // },

        // {
        //     label: 'Question 1',
        //     key: 'service_report.question_1',
        //     type: 'text',
        //     placeholder: 'Enter the number',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Service Report',
        // },

        // {
        //     label: 'Question 2',
        //     key: 'service_report.question_2',
        //     type: 'text',
        //     placeholder: 'Enter the number',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Service Report',
        // },

        // {
        //     label: 'Question 3',
        //     key: 'service_report.question_3',
        //     type: 'text',
        //     placeholder: 'Enter the number',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Service Report',
        // },

        // {
        //     label: 'Service Remarks',
        //     key: 'service_report.remarks',
        //     type: 'editor',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Service Report',
        // },

        // {
        //     type: 'custom',
        //     visible: true,
        //     span: 24,
        //     tab: 'Invoices',
        //     component: InvoiceComponent,
        // },

        // {
        //     type: 'custom',
        //     visible: true,
        //     span: 24,
        //     tab: 'Payments',
        //     component: PaymentsComponent,
        // },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: 'General'
            });
        });
    }

    const defaults = response.defaults;

    if (defaults) {
        for (let key in defaults) {
            const field = formFields.find(field => field.key == key);
            if (field) {
                field.value = defaults[key];
            }
        }
    }

    return formFields;
}

const preferencesFields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [{
        type: 'divider',
        heading: moduleName + ' Numbering',
        visible: true,
        span: 24,
    }, {
        label: 'Prefix',
        key: 'number_prefix',
        type: 'text',
        placeholder: 'Enter the prefix',
        visible: true,
        width: '25%'
    },
    {
        label: 'Number',
        key: 'next_number',
        type: 'number',
        placeholder: 'Enter the number',
        visible: true,
        width: '25%'
    }, {
        label: 'Suffix',
        key: 'number_suffix',
        type: 'text',
        placeholder: 'Enter the suffix',
        visible: true,
        width: '25%'
    }, {
        type: 'divider',
        heading: 'General Settings',
        visible: true,
        span: 24,
    }, {
        label: 'Due Date will be X days from the ' + moduleName + ' Date',
        key: 'invoice_due_date',
        type: 'number',
        placeholder: 'Enter the due date',
        visible: moduleName == 'Invoices' ? true : false,
        width: '50%',
        value: 0
    }, {
        label: 'Disable sending auto emails on save?',
        key: 'disable_sending_auto_emails_on_save',
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%'
    }, {
        label: 'Allow editing of Sent ' + moduleName + '?',
        key: 'allow_editing_of_sent_' + moduleName.toLowerCase(),
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%'
    }, {
        label: 'Allow 0 value ' + moduleName + '?',
        key: 'allow_zero_value_' + moduleName.toLowerCase(),
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%'
    }, {
        label: 'Allow negative total for ' + moduleName + '?',
        key: 'allow_negative_total_for_' + moduleName.toLowerCase(),
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%'
    }];


    return formFields;
}

export const inquiryMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const preview = (props) => {
    const source = { uri: 'https://www.africau.edu/images/default/sample.pdf' };


    return <View flex>
        {Platform.OS === 'web' ?
            <iframe src="https://www.africau.edu/images/default/sample.pdf" width="100%" height="100%" style={{
                height: Dimensions.get('window').height - 180,
            }} />
            :
            <WebView
                source={source}
                style={{
                    height: Dimensions.get('window').height - 100,
                    flex: 1,
                }}
            />
        }
    </View>
}

export const inquiryMain = {
    fields: inquiryfields,
    mobileCard: null,
    preferencesFields: preferencesFields,
    customActions: (moduleName, navigationFn, route, row, setOpenModal, openCustomModal) => {
        let options = [{
            label: 'Mark as Pending Call',
            key: 'mark_as_pending_call',
            fn: (props) => {
                const row = props.row;
                row.status = 'Pending Call';
                api.update(moduleName, row, row._id).then((res) => {
                    DeviceEventEmitter.emit('reloadListing');
                })
            }
        }, {
            label: 'Mark as Un Answered',
            key: 'mark_as_un_answered',
            fn: (props) => {
                const row = props.row;
                row.status = 'Un Answered';
                api.update(moduleName, row, row._id).then((res) => {
                    DeviceEventEmitter.emit('reloadListing');
                })
            }
        }, {
            label: 'Mark as Interested',
            key: 'mark_as_interested',
            fn: (props) => {
                const row = props.row;
                row.status = 'Interested';
                api.update(moduleName, row, row._id).then((res) => {
                    DeviceEventEmitter.emit('reloadListing');
                })
            }
        }, {
            label: 'Mark as Not Interested',
            key: 'mark_as_not_interested',
            fn: (props) => {
                const row = props.row;
                row.status = 'Not Interested';
                api.update(moduleName, row, row._id).then((res) => {
                    DeviceEventEmitter.emit('reloadListing');
                })
            }
        }, {
            label: 'Mark as Closed',
            key: 'mark_as_closed',
            fn: (props) => {
                const row = props.row;
                row.status = 'Closed';
                api.update(moduleName, row, row._id).then((res) => {
                    DeviceEventEmitter.emit('reloadListing');
                })
            }
        }];

        options = [
            ...options.map((item) => {
                return { ...item, ignore_permission: true }
            }),
        ]

        return options;
    },
}
