import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable, useSortBy, usePagination, useFlexLayout, useResizeColumns, useRowSelect } from 'react-table';
import { Button, Card, Colors, ExpandableSection, GridList, GridView, Image, ProgressBar, SortableList, Text, TouchableOpacity, View } from 'react-native-ui-lib';
import { Checkbox, Dropdown, Menu, Tooltip, Table, Image as WebImageView, Progress, Flex } from 'antd';
import { AutoCompleteField, CheckboxField, CustomDropDown, CustomModal, CustomSelect, DangerButton, DateField, DurationField, Label, NumberField, PrimaryButton, SecondaryButton, SelectField, SerialNumberTextArea, SwitchField, TableField, TextField, TimeField, checkVisibility } from './DynamicForm';
import { Alert, Dimensions, FlatList, Platform, StyleSheet, ScrollView } from 'react-native';
import _ from 'lodash';
import { Icon } from 'react-native-eva-icons';
// import moment from 'moment';
// import utc from 'moment/plugin/utc';
// import timezone from 'moment/plugin/timezone';
import { GlobalModalStore } from '../models/ModalStore';
import { useNavigation } from '@react-navigation/native';
import { useNavigate } from 'react-router-dom';
import { FlashList } from '@shopify/flash-list';
import moment from 'moment-timezone';
import { getLocales } from 'expo-localization';

// moment.extend(utc);
// moment.extend(timezone);


const dataItem = _.times(30, index => {
  let text = `${index}`;
  if (index === 3) {
    text = 'Locked item';
  }

  return {
    text,
    id: `${index}`,
    locked: index === 3
  };
});

const SidebarStatus = ({ color, text, actionFunctions, item, radius }) => {
  const [containerWidth, setContainerWidth] = React.useState(0);

  return <CustomDropDown
    value={""}
    onChange={(value) => {

    }}
    options={
      [
        ...(actionFunctions && actionFunctions(item).map((el) => {
          return {
            label: el.label,
            value: el.key,
            onSelect: (value) => {
              el.fn({ row: item });
            },
            onPress: (value) => {
              el.fn({ row: item });
            }
          }
        }) || [])
      ]
    }
  >
    <View spread backgroundColor={color} style={{ width: 42, height: '100%', borderBottomEndRadius: radius, borderTopEndRadius: radius }}
    >
      <View center>
        <Icon name="more-vertical-outline" fill={'white'} style={{ height: 16, width: 16, marginTop: 10 }} />
      </View>
      <View flex center style={{ position: 'relative', justifyContent: 'flex-end' }}
        onLayout={(e) => {
          const { height } = e.nativeEvent.layout;
          setContainerWidth(height - 16);
        }}>
        <Text
          style={{
            width: containerWidth,
            transform: [
              { rotate: "90deg" },
              { translateX: -1 * (containerWidth / 2) }
            ],
            color: 'white', fontSize: 10, letterSpacing: 1,
            textAlign: 'right',
          }}
        >
          {text}
        </Text>
      </View>
    </View>
  </CustomDropDown>;
};

const DeleteCard = ({ color, text, radius }) => {
  const [containerWidth, setContainerWidth] = React.useState(0);

  return <View spread backgroundColor={color} style={{ width: 36, height: '100%', borderBottomEndRadius: radius, borderTopEndRadius: radius }}
  >
    <View center>
      <Icon name="trash-outline" fill={'white'} style={{ height: 16, width: 16, marginTop: 10 }} />
    </View>
    <View flex center style={{ position: 'relative', justifyContent: 'flex-end' }}
      onLayout={(e) => {
        const { height } = e.nativeEvent.layout;
        setContainerWidth(height - 16);
      }}>
      <Text
        style={{
          width: containerWidth,
          transform: [
            { rotate: "90deg" },
            { translateX: -1 * (containerWidth / 2) }
          ],
          color: 'white', fontSize: 10, letterSpacing: 1,
          textAlign: 'right',
        }}
      >
        {text}
      </Text>
    </View>
  </View>;
};

const DynamicTable = ({
  data = [],
  columns = [],
  allColumns = [],
  actions = null,
  actionFunctions = () => [],
  total = 0,
  pageSize = 10,
  pageIndex = 0,
  sortColumn = null,
  sortDirection = null,
  onQueryParams = null,
  cfAdd = null,
  showCheckbox = false,
  onCheckboxChange = null,
  onRowClick = null,
  resizable = false,
  updateData = null,
  hideLabelOnMobile = false,
  CustomMobileCard = null,
  editable = false,
  saveColumns = null,
  isGrouped = false,
  connectedObject = null,
  useForm = null,
  tableKey = null,
  viewType = 'table',
  viewOnly = false,
  mono = false,
  onRefresh = null,
  loading = false,
  customColumnRender = {},
  compact = false,

  startDate = null,
  endDate = null,
  selectedFilters = null,
  groupBy = null,
}) => {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const dataMemo = useMemo(() => data, [data]);
  const columnDefs = useMemo(() => columns, [columns]);
  const [cfModalVisible, setCfModalVisible] = useState(false);
  const [allColumnsSorted, setAllColumnsSorted] = useState(allColumns);
  const [items, setItems] = useState(dataItem);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editFieldModalVisible, setEditFieldModalVisible] = useState(false);
  const [editFieldModalData, setEditFieldModalData] = useState(null);
  const [editFieldModalIndex, setEditFieldModalIndex] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
  let navigation = useNavigation();

  let navigate = null;

  if (Platform.OS === 'web') {
    navigate = useNavigate();
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === 'web') {
      let paramsString = "";
      if (params) {
        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
      navigate("/" + path + paramsString);
    } else {
      if (path.includes("report/")) {
        navigation.push("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.push(path, params);
      }
    }
  };

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable({
    columns,
    data: dataMemo,
    defaultColumn,
  },
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
  )

  useEffect(() => {
    if (state.columnResizing?.columnWidths) {
      if (state.columnResizing?.columnWidths) {
        Object.keys(state.columnResizing?.columnWidths).forEach((key) => {
          let column = columns.find((col) => col.id == key);
          if (!column) {
            column = columns.find((col) => col.field == key);
          }
          if (column) {
            column.width = state.columnResizing?.columnWidths[key];
          }
          setAllColumnsSorted(columns);
          saveColumns && saveColumns(columns, false);
        });
      }
    }
  }, [state.columnResizing?.isResizingColumn]);

  useEffect(() => {
    if (allColumnsSorted.length != allColumns.length) {
      setAllColumnsSorted(allColumns)
    }
  }, [allColumns]);

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row.original);
    }
  };

  const handleCheckboxChange = (rowId, isChecked) => {
    const newSelectedRows = new Set(selectedRows);
    if (isChecked) {
      newSelectedRows.add(rowId);
    } else {
      newSelectedRows.delete(rowId);
    }
    setSelectedRows(newSelectedRows);
    if (onCheckboxChange) {
      onCheckboxChange(newSelectedRows);
    }
  };

  const handleSelectAll = (isChecked) => {
    const newSelectedRows = new Set();
    if (isChecked) {
      rows.forEach((row) => {
        newSelectedRows.add(row.original._id);
      });
    }
    setSelectedRows(newSelectedRows);
    if (onCheckboxChange) {
      onCheckboxChange(newSelectedRows);
    }
  }

  const handleModelChange = (value, field, index, cell = false) => {
    const newData = [...data];
    const id = field.id ? field.id : field.accessor;

    if (index > -1) {
      newData[index] = { ...newData[index], [id]: value };
      updateData(newData);
      field.onTableChange && field.onTableChange({ value, field, index, cell, updateData, data: newData });
    }
  };

  const headerProps = (props, { column }) => getStyles(props, column.align)
  const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

  const getStyles = (props, align = 'left') => [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ]

  const toggleItemSelection = useCallback((item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter(selectedItem => ![item.id].includes(selectedItem.id)));
    } else {
      setSelectedItems(selectedItems.concat(item));
    }
  }, [selectedItems, setSelectedItems]);

  const renderItem = useCallback(({ item, index: _index }) => {
    return (
      <View row style={{ cursor: 'grab' }}>
        <Checkbox defaultChecked={item.visible} onChange={(e) => {
          const newColumns = [...allColumnsSorted].map((col) => {
            if (col.field == item.field) {
              return { ...col, visible: e.target.checked };
            }
            return { ...col, visible: col.visible };
          });
          setAllColumnsSorted(newColumns);
        }} />
        <Image assetName="drag" style={{ width: 20, height: 20, opacity: 0.2 }} />
        <Text text-90N grey10 key={item.field} ellipsizeMode={'tail'}>
          {item.label}
        </Text>
      </View>
    );
  }, [selectedItems, toggleItemSelection, allColumnsSorted]);

  const updateEditFieldModalData = (value, field) => {
    const newData = { ...editFieldModalData };
    const id = field.id ? field.id : field.accessor;
    newData[id] = value;
    setEditFieldModalData(newData);
  };

  const getFieldValue = (row, field, column = {}, rawDate = false) => {
    if (field == "progress") {
      console.log("row", row);
      console.log("field", field);
    }


    if (!field) return '';
    if (!row) return '';

    let value = row[field];

    if (field.includes('[].')) {
      const fieldParts = field.split('[].');
      const fieldName = fieldParts[0];
      const subFieldName = fieldParts[1];

      let values = [];

      if (row[fieldName]) {
        for (let i = 0; i < row[fieldName].length; i++) {
          values.push(row[fieldName][i][subFieldName]);
        }
      }

      if (column.customTagStyle) {
        return <View row>
          {values.map((value, key) => <View key={key.toString()} style={column.customTagWrapperStyle}>
            <Text style={column.customTagStyle}>
              {value}
            </Text>
          </View>)}
        </View>
      }

      return <Text
        style={[{
          maxWidth: '100%',
          fontFamily: (mono) ? "JetBrainsMonoRegular" : 'SourceSansProSemiBold'
        }]}
        ellipsizeMode
        numberOfLines={1}>
        {values.join(', ')}
      </Text>
    } else if (field.includes('[]')) {
      const fieldParts = field.split('[]');
      const fieldName = fieldParts[0];

      let values = [];

      if (row[fieldName]) {
        for (let i = 0; i < row[fieldName].length; i++) {
          values.push(row[fieldName][i]);
        }
      }

      if (column.customTagStyle) {
        return <View row>
          {values.map((value, key) => <View key={key.toString()} style={column.customTagWrapperStyle}>
            <Text style={column.customTagStyle}>
              {value}
            </Text>
          </View>)}
        </View>
      }

      return <Text
        style={[{
          maxWidth: '100%',
          fontFamily: (mono) ? "JetBrainsMonoRegular" : 'SourceSansProSemiBold'
        }]}
        ellipsizeMode
        numberOfLines={1}>
        {values.join(', ')}
      </Text>
    }

    if (!value) {
      value = field.split('.')?.reduce((obj, prop) => obj && obj[prop], row);
    }

    if (column?.type == 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (Platform.OS == 'web') {
      if (column?.type == 'date' && value) {
        if (rawDate) return value;
        const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
        return moment(localDueDate).format(column?.format || 'DD MMM YYYY');
      }

      if (column?.type == 'datetime' && value) {
        if (rawDate) return value;
        const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
        return moment(localDueDate).format(column?.format || 'DD MMM YYYY hh:mm A');
      }

      if (column?.type == 'time' && value) {
        if (rawDate) return value;
        const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
        return moment(localDueDate).format(column?.format || 'hh:mm A');
      }

      if (column?.type == 'image' && value) {
        return <div onClick={(e) => {
          e.stopPropagation();
        }}>
          <WebImageView width={50} src={value} style={{
            width: 50,
            height: 50,
            resizeMode: 'cover',
            borderRadius: 5,
          }} />
        </div>
      }
    } else {
      if (column?.type == 'date' && value) {
        if (rawDate) return value;
        const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
        return moment(localDueDate).format(column?.format || 'DD MMM YYYY');
      }

      if (column?.type == 'datetime' && value) {
        if (rawDate) return value;
        const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
        return moment(localDueDate).format(column?.format || 'DD MMM YYYY hh:mm A');
      }

      if (column?.type == 'time' && value) {
        if (rawDate) return value;
        const localDueDate = moment(value).tz(moment.tz.guess() || 'Asia/Kolkata');
        return moment(localDueDate).format(column?.format || 'hh:mm A');
      }

      if (column?.type == 'select') {
        if (column?.options?.length > 0) {
          const option = column?.options?.find((opt) => opt.value == value);
          if (option) {
            return option.label;
          }
        }
      }
    }

    if (value) {
      return value
    };

    return field.split('.')?.reduce((obj, prop) => obj && obj[prop], row);
  }

  console.log("startDate", startDate);


  const body = <div {...getTableBodyProps()} className="tbody">
    {rows.length > 0 ? <>{!isGrouped ? rows.map((row, index) => {
      prepareRow(row)
      return (
        <div key={row.original._id || index.toString()} {...row.getRowProps()} className={`tr 
          ${row.original.approvalStatus == 'Pending' ? 'tr__pending' : ''}
          ${row.original.approvalStatus == 'Rejected' ? 'tr__rejected' : ''}
        `} style={onRowClick ?
            { ...row.getRowProps().style, cursor: "pointer" } :
            { ...row.getRowProps().style }
          } onClick={() => handleRowClick(row)}>

          {row.cells.map((cell, cellIndex) => {
            if (cell.column.id == "actions") {
              return actionsFn(row, cell, index);
            }

            if (tableKey) {
              cell.column.value = getFieldValue(row.original, cell.column.id);
              let cellValue = cell.column.value;

              cell.column.key = cell.column.id;

              return <div key={cellIndex.toString()} {...cell.getCellProps(cellProps)} className={`td ${cellIndex === 0 ? `sticky__left` : ''} ${cell.column.grow ? 'column_grow' : ''}`}>

                {showCheckbox &&
                  <Checkbox checked={selectedRows.has(row.original._id)} onClick={(e) => {
                    e.stopPropagation();
                  }} onChange={(e) => {
                    handleCheckboxChange(row.original._id, e.target.checked);
                  }} />
                }

                {checkVisibility(cell.column, row.original, null) ? <div className="td__content">
                  {cell.column.type === "text" && <TextField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "number" && <NumberField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "select" && <SelectField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "duration" && <DurationField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "autocomplete" && <AutoCompleteField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "date" && <DateField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "time" && <TimeField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "checkbox" && <CheckboxField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "switch" && <SwitchField field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "custom" && <cell.column.component field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "tags_text_area" && <SerialNumberTextArea field={cell.column} useForm={useForm} tKey={tableKey} tIndex={index} viewOnly={!cell.column.editable || viewOnly} />}

                  {cell.column.type === "progress" && <View>
                    {/* <Text style={{ fontSize: 12, color: '#000' }}>{cell.column.value}</Text> */}
                    <View style={{
                      position: 'relative',
                      width: '100%',
                    }}>

                      {Platform.OS != 'web' && <ProgressBar progress={10} progressColor="#29459C" style={{
                        height: 20
                      }} />}
                      {Platform.OS == 'web' && <Flex gap="small" vertical><Progress percent={cellValue} /></Flex>}
                      {Platform.OS != 'web' && <Text style={{
                        position: 'absolute',
                        right: 5,
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        color: '#ffffff',
                        fontFamily: 'SourceSansProSemiBold',
                      }}>{cellValue}%</Text>}
                    </View>
                  </View>}

                  {cell.column.type == "moduleLink" && <div className='pointer' onClick={() => {
                    if (Platform.OS === 'web') {
                      setOpenModal({
                        [row.original.moduleName]: {
                          isVisible: true,
                          moduleName: row.original.moduleName,
                          id: row.original.moduleId,
                          viewOnly: true,
                          afterSaveEvent: 'reloadListing',
                        }
                      })
                    } else {
                      navigationFn('New', {
                        moduleName: row.original.moduleName,
                        id: row.original.moduleId,
                        viewOnly: true,
                      });
                    }
                  }}>
                    <Text
                      monoTableBody={row.original.boldRow ? false : mono}
                      monoTableHeading={row.original.boldRow}
                      style={[
                        cell.column.customCellStyle,
                        { maxWidth: '100%' },
                        { color: '#007bff', textDecorationLine: 'underline' }
                      ]} ellipsizeMode={'tail'} numberOfLines={1}>
                      {cell.column.value}
                    </Text>
                  </div>}

                  {cell.column.type == "reportLink" && <div className='pointer' onClick={() => {
                    if (Platform.OS === 'web') {
                      const params = {
                        moduleName: row.original.moduleName,
                        startDate: startDate,
                        endDate: endDate,
                        quickFilterKey: row.original.quickFilterKey,
                        quickFilterValue: row.original.quickFilterValue,
                        selectedFilters: selectedFilters,
                        groupBy: groupBy,
                      }

                      let paramsString = "";
                      if (params) {
                        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                      }
                      let path = "report/" + row.original.moduleName;
                      navigate("/" + path + paramsString);
                    } else {
                      navigationFn('report', {
                        moduleName: row.original.moduleName,
                        startDate: startDate,
                        endDate: endDate,
                        quickFilterKey: row.original.quickFilterKey,
                        quickFilterValue: row.original.quickFilterValue,
                        selectedFilters: selectedFilters,
                        groupBy: groupBy,
                        viewOnly: true,
                      });
                    }
                  }}>
                    {console.log("row.original.boldRow ? false : mono", row.original.boldRow ? false : mono)}
                    <Text
                      monoTableBody={row.original.boldRow ? false : mono}
                      monoTableHeading={row.original.boldRow}
                      style={[
                        cell.column.customCellStyle,
                        { maxWidth: '100%' },
                        { color: '#007bff', textDecorationLine: 'underline' }
                      ]} ellipsizeMode={'tail'} numberOfLines={1}>
                      {cell.column.value}
                    </Text>
                  </div>}

                </div> : null}

              </div>;
            }

            const value = getFieldValue(row.original, cell.column.field, cell.column);

            if (cell.column.field.includes('[]')) {
              return <div {...cell.getCellProps(cellProps)} className={`td ${cellIndex === 0 ? `sticky__left ${showCheckbox ? 'left_with_checkbox' : ''}` : ''} ${cell.column.grow ? 'column_grow' : ''}`}>

                <View row>
                  {(showCheckbox && cellIndex === 0) &&
                    <Checkbox checked={selectedRows.has(row.original._id)} onClick={(e) => {
                      e.stopPropagation();
                    }} onChange={(e) => {
                      handleCheckboxChange(row.original._id, e.target.checked);
                    }} />
                  }

                  <View style={cell.column.customCellStyle}>
                    {value}
                  </View>
                </View>
              </div>;
            }

            if (customColumnRender[cell.column.field]) {
              cell.column.value = getFieldValue(row.original, cell.column.field);

              return <div {...cell.getCellProps(cellProps)} className={`td ${cellIndex === 0 ? `sticky__left ${showCheckbox ? 'left_with_checkbox' : ''}` : ''} ${cell.column.grow ? 'column_grow' : ''}`}>
                <View row>
                  {(showCheckbox && cellIndex === 0) && <Checkbox checked={selectedRows.has(row.original._id)} onClick={(e) => {
                    e.stopPropagation();
                  }} onChange={(e) => {
                    handleCheckboxChange(row.original._id, e.target.checked);
                  }} />}

                  {customColumnRender[cell.column.field](cell.column.value, row.original, cell.column, navigationFn, setOpenModal)}
                </View>
              </div>
            }

            return <div {...cell.getCellProps(cellProps)} className={`td ${cellIndex === 0 ? `sticky__left ${showCheckbox ? 'left_with_checkbox' : ''}` : ''} ${cell.column.grow ? 'column_grow' : ''}`}>

              <View row>
                {(showCheckbox && cellIndex === 0) && <Checkbox checked={selectedRows.has(row.original._id)} onClick={(e) => {
                  e.stopPropagation();
                }} onChange={(e) => {
                  handleCheckboxChange(row.original._id, e.target.checked);
                }} />}

                <View style={[cell.column.customWrapperCellStyle, { maxWidth: '100%' }]}>
                  {cell.column.type == "moduleLink" ? <div className='pointer' onClick={() => {
                    if (Platform.OS === 'web') {
                      setOpenModal({
                        [row.original.moduleName]: {
                          isVisible: true,
                          moduleName: row.original.moduleName,
                          id: row.original.moduleId,
                          viewOnly: true,
                          afterSaveEvent: 'reloadListing',
                        }
                      })
                    } else {
                      navigationFn('New', {
                        moduleName: row.original.moduleName,
                        id: row.original.moduleId,
                        viewOnly: true,
                      });
                    }
                  }}>
                    <Text
                      monoTableBody={row.original.boldRow ? false : mono}
                      monoTableHeading={row.original.boldRow}
                      style={[
                        cell.column.customCellStyle,
                        { maxWidth: '100%' },
                        { color: '#007bff', textDecorationLine: 'underline' }
                      ]} ellipsizeMode={'tail'} numberOfLines={1}>
                      {value}
                    </Text>
                  </div> : cell.column.type == "reportLink" ? <div className='pointer' onClick={() => {
                    if (Platform.OS === 'web') {
                      const params = {
                        moduleName: row.original.moduleName,
                        startDate: startDate,
                        endDate: endDate,
                        quickFilterKey: row.original.quickFilterKey,
                        quickFilterValue: row.original.quickFilterValue,
                        selectedFilters: selectedFilters,
                        groupBy: groupBy,
                      }
                      let paramsString = "";
                      if (params) {
                        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                      }
                      let path = "report/" + row.original.moduleName;
                      navigate("/" + path + paramsString);
                    } else {
                      navigationFn('report', {
                        moduleName: row.original.moduleName,
                        startDate: startDate,
                        endDate: endDate,
                        quickFilterKey: row.original.quickFilterKey,
                        quickFilterValue: row.original.quickFilterValue,
                        selectedFilters: selectedFilters,
                        groupBy: groupBy,
                        viewOnly: true,
                      });
                    }
                  }}>
                    <Text
                      monoTableBody={row.original.boldRow ? false : mono}
                      monoTableHeading={row.original.boldRow}
                      style={[
                        cell.column.customCellStyle,
                        { maxWidth: '100%' },
                        { color: '#007bff', textDecorationLine: 'underline' }
                      ]} ellipsizeMode={'tail'} numberOfLines={1}>
                      {value}
                    </Text>
                  </div> : <View row centerV>
                    {(cell.column.warningColumn && (row.original.warning?.length > 0)) && <View marginR-5>
                      <Tooltip title={row.original.warning}>
                        <Icon name="alert-circle-outline" fill={'#ff9800'} style={{ height: 16, width: 16 }} />
                      </Tooltip>
                    </View>}
                    <Text
                      monoTableBody={row.original.boldRow ? false : mono}
                      monoTableHeading={row.original.boldRow}
                      style={[cell.column.customCellStyle, {
                        maxWidth: '100%',
                        fontFamily: (mono) ? "JetBrainsMonoRegular" : 'SourceSansProSemiBold'
                      }]} ellipsizeMode numberOfLines={1}>
                      {value}
                    </Text>
                  </View>}
                </View>
              </View>
            </div>
          })}
        </div>
      )
    }) : rows.map((row, index) => {
      prepareRow(row)

      return (<>
        <ExpandableSection
          key={row.original._id || index.toString()}
          expanded={expandedRows[row.id]}
          onPress={() => {
            setExpandedRows({
              ...expandedRows,
              [row.id]: !expandedRows[row.id]
            })
          }}
          sectionHeader={<>
            <div style={{ paddingLeft: 15, display: 'flex', height: 40, alignItems: 'center' }}>
              <Text style={{ textTransform: 'uppercase', letterSpacing: 1.6, fontWeight: 600, fontSize: 13 }}>
                {"" + row.original._id}
              </Text>
              <div style={{ border: '1px solid #e0e0e0', padding: '0px 7px', borderRadius: '26%', fontSize: 12, marginLeft: 10 }}>
                <Text>
                  {row.subRows.length}
                </Text>
              </div>
            </div>
          </>}
        >
          {row.subRows.map((subRow, subIndex) => {
            prepareRow(subRow)
            return <div key={subRow.original._id || subIndex.toString()} {...subRow.getRowProps()} className="tr" style={onRowClick ?
              { ...subRow.getRowProps().style, cursor: "pointer" } :
              { ...subRow.getRowProps().style }
            } onClick={() => handleRowClick(subRow)}>
              {showCheckbox && <div className="td sticky__left" style={{ minWidth: 18 }}>
                <Checkbox checked={selectedRows.has(subRow.original._id)} onClick={(e) => {
                  e.stopPropagation();
                }} onChange={(e) => {
                  handleCheckboxChange(subRow.original._id, e.target.checked);
                }} />
              </div>}
              {subRow.cells.map((cell, cellIndex) => {

                if (cell.column.id == "actions") {
                  return actionsFn(subRow, cell, subIndex);
                }

                if (cell.column.editable) {
                  cell.column.value = getFieldValue(subRow.original, cell.column.id);

                  return <div key={cellIndex.toString()} {...cell.getCellProps(cellProps)} className={`td ${cellIndex === 0 ? `sticky__left ${showCheckbox ? 'left_with_checkbox' : ''}` : ''} ${cell.column.grow ? 'column_grow' : ''}`}>

                    {/* {cell.column.type === "text" && <TextField field={cell.column} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "number" && <NumberField field={cell.column} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "date" && <DateField field={cell.column} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "time" && <TimeField field={cell.column} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "select" && <SelectField field={{ ...cell.column, borderLess: true }} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "checkbox" && <CheckboxField field={cell.column} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "switch" && <SwitchField field={cell.column} connectedObject={row.original} onTableInputChange={(value) => handleModelChange(value, cell.column, index, cell)} />}

                    {cell.column.type === "custom" && <cell.column.component field={cell.column} updateField={(e, field) => {
                      handleModelChange(e, field, editFieldModalIndex, cell)
                    }} />} */}
                  </div>;
                }

                return <div {...cell.getCellProps(cellProps)} className={`td ${cellIndex === 0 ? `sticky__left ${showCheckbox ? 'left_with_checkbox' : ''}` : ''} ${cell.column.grow ? 'column_grow' : ''}`}>
                  {cell.render('Cell')}
                </div>
              })}
            </div>
          })}
        </ExpandableSection>
      </>);
    })}</> : <div className="tr">
      <div className="td" style={{ textAlign: 'center' }}>
        <Text>No data found</Text>
      </div>
    </div>
    }

  </div>;

  const pagination = <View row spread centerV marginB-10 style={{
    width: '100%'
  }}>
    <View>
      <CustomSelect
        value={pageSize}
        borderLess
        onChange={(e) => {
          onQueryParams({
            pageSize: e,
          })
        }}
        options={[10, 25, 50, 100].map((pageSize) => ({
          value: pageSize,
          label: `${pageSize} per page`,
        }))}
      />
    </View>
    <View row center>
      <View row center>
        <TouchableOpacity onPress={() => {
          onQueryParams({
            pageIndex: pageIndex - 1,
          })
        }}>
          <Icon name="arrowhead-left-outline" width={16} height={16} fill={"rgb(80, 92, 101)"} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {
          onQueryParams({
            pageIndex: pageIndex - 1,
          })
        }}>
          <Icon name="chevron-left-outline" width={16} height={16} fill={"rgb(80, 92, 101)"} />
        </TouchableOpacity>
      </View>
      <Text style={{
        color: "rgb(139, 149, 156)"
      }}>{`${(pageIndex - 1) * pageSize || 1} to ${(pageIndex * pageSize) > total ? total : (pageIndex * pageSize)} of ${total} Records`}</Text>
      <View row center>
        <TouchableOpacity onPress={() => {
          onQueryParams({
            pageIndex: pageIndex + 1,
          })
        }}>
          <Icon name="chevron-right-outline" width={16} height={16} fill={"rgb(80, 92, 101)"} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {
          onQueryParams({
            pageIndex: pageIndex + 1,
          })
        }}>
          <Icon name="arrowhead-right-outline" width={16} height={16} fill={"rgb(80, 92, 101)"} />
        </TouchableOpacity>
      </View>
    </View>
  </View>;

  const mainWidth = Dimensions.get('window').width - 330;

  return (<>
    {(Platform.OS == 'web' && viewType == 'table') && <div {...getTableProps()} className={`table sticky ${mono ? "mono" : ""} ${compact ? 'compact' : ''}`}>
      {/* {isGrouped ? <>{body}</> : <>{head()}{body}</>}  */}
      {head()}
      {body}
      {onQueryParams ? pagination : null}
    </div>}

    {(Platform.OS == 'web' && viewType == 'cards') && <View style={{ marginTop: 10 }} flex>
      <GridList
        data={data}
        renderItem={({ item, index }) => {
          if (CustomMobileCard) {
            return <CustomMobileCard item={item} index={index} getFieldValue={getFieldValue} />
          }

          return <Card onPress={editable ? () => {
            setEditFieldModalData(item);
            setEditFieldModalIndex(index);
            setEditFieldModalVisible(true);
          } : null} flex margin-20 padding-15 style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
            {columns.map((column, colIndex) => {
              const value = getFieldValue(item, column.field);

              const checkRight = !column.flexBasis ? value?.length <= 16 ? colIndex % 2 == 1 ? true : false : false : false;
              const flexBasis = { flexBasis: column.flexBasis ?? value?.length >= 16 ? '100%' : '50%' };

              return <>
                <View
                  key={colIndex.toString()}
                  right={checkRight}
                  style={[flexBasis]}>
                  {hideLabelOnMobile == false && <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>{column.label}</Text>}
                  <Text style={{ marginBottom: 5 }}>{value ? "" + value : ""}</Text>
                  {/* {hideLabelOnMobile == false && <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>{column.label}</Text>}
                  <Text style={{ marginBottom: 5 }}>{value ? "" + value : ""}</Text> */}
                </View>
              </>
            })}
          </Card>
        }}
        keyExtractor={(item, index) => index.toString()}
        // maxItemWidth={mainWidth / 3}
        containerWidth={mainWidth}
        numColumns={3}
        itemSpacing={20}
        listPadding={6}
      />
    </View>}

    {(Platform.OS != 'web') && <View style={{ marginTop: 10 }} flex>
      <FlashList
        data={data.filter((item) => !item.hideOnMobile)}
        estimatedItemSize={170}
        renderItem={({ item, index }) => {
          if (CustomMobileCard) {
            return <CustomMobileCard item={item} index={index} getFieldValue={getFieldValue} onRowClick={onRowClick} actionFunctions={actionFunctions} valueText={valueText} columns={columns} DeleteCard={DeleteCard} SidebarStatus={SidebarStatus} />
          }

          if (item.auto_open) {
            editCardFn(setEditFieldModalData, item, setEditFieldModalIndex, index, setEditFieldModalVisible);

            const newData = [...data];
            newData[index].auto_open = false;
            updateData && updateData(newData);
          }

          return (editable && !viewOnly) ? <View padding-10>
            <Card
              flex style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <TouchableOpacity padding-15 paddingT-10 paddingB-10 flex onPress={() => {
                editCardFn(setEditFieldModalData, item, setEditFieldModalIndex, index, setEditFieldModalVisible);
              }}>
                <View marginB-10 row spread style={{ width: '100%' }}>
                  <View flex>
                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                      {columns[0] ? getFieldValue(item, columns[0].field, columns[0]) : ""}
                    </Text>
                    <View>
                      <View flex>
                        {columns.length > 2 ? <View row marginB-6 flex>
                          <View>
                            <Text lightCardTitle>{columns[1] ? columns[1].label : ""}: </Text>
                          </View>
                          <View flex>
                            {valueText(columns[1])}
                          </View>
                        </View> : null}
                        {columns.length > 3 ? <View row marginB-6 flex>
                          <View>
                            <Text lightCardTitle>{columns[2] ? columns[2].label : ""}: </Text>
                          </View>
                          <View flex>
                            {valueText(columns[2])}
                          </View>
                        </View> : null}
                      </View>
                    </View>
                  </View>
                </View>

                {columns.length > 4 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
                {columns.length > 4 ? <View row spread style={{ width: '100%' }}>
                  {columns.length > 4 ? <View row flex>
                    <View>
                      <Text lightCardTitle>{columns[3] ? columns[3].label : ""}: </Text>
                    </View>
                    <View flex>
                      {valueText(columns[3])}
                    </View>
                  </View> : null}
                  {columns.length > 5 ? <View row flex>
                    <View>
                      <Text lightCardTitle>{columns[4] ? columns[4].label : ""}: </Text>
                    </View>
                    <View flex>
                      {valueText(columns[4])}
                    </View>
                  </View> : null}
                </View> : null}

                {columns.length > 6 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
                {columns.length > 6 ? <View row spread style={{ width: '100%' }}>
                  {columns.length > 6 ? <View row flex>
                    <View>
                      <Text lightCardTitle>{columns[5] ? columns[5].label : ""}: </Text>
                    </View>
                    <View flex>
                      {valueText(columns[5])}
                    </View>
                  </View> : null}
                  {columns.length > 7 ? <View row flex>
                    <View>
                      <Text lightCardTitle>{columns[6] ? columns[6].label : ""}: </Text>
                    </View>
                    <View flex>
                      {valueText(columns[6])}
                    </View>
                  </View> : null}
                </View> : null}

                {columns.length > 8 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
                {columns.length > 8 ? <View row spread style={{ width: '100%' }}>
                  {columns.length > 8 ? <View row flex>
                    <View>
                      <Text lightCardTitle>{columns[7] ? columns[7].label : ""}: </Text>
                    </View>
                    <View flex>
                      {valueText(columns[7])}
                    </View>
                  </View> : null}
                  {columns.length > 9 ? <View row flex>
                    <View>
                      <Text lightCardTitle>{columns[8] ? columns[8].label : ""}: </Text>
                    </View>
                    <View flex>
                      {valueText(columns[8])}
                    </View>
                  </View> : null}
                </View> : null}
              </TouchableOpacity>

              <TouchableOpacity onPress={() => {
                Alert.alert("Delete", "Are you sure you want to delete this record?", [
                  {
                    text: "Cancel",
                    onPress: () => {

                    },
                    style: "cancel"
                  },
                  {
                    text: "Delete",
                    onPress: () => {
                      updateData && updateData(data.filter((d, i) => i != index));
                    }
                  }
                ]);
              }}>
                <DeleteCard color={Colors.red30}
                  text={"Delete"}
                  radius={10}
                />
              </TouchableOpacity>
            </Card>
          </View> : <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
            <TouchableOpacity padding-10 flex onPress={onRowClick ? () => {
              onRowClick && onRowClick(item);
            } : (item.moduleName && item.moduleId) ? () => {
              if (Platform.OS === 'web') {
                setOpenModal({
                  [item.moduleName]: {
                    isVisible: true,
                    moduleName: item.moduleName,
                    id: item.moduleId,
                    viewOnly: true,
                    afterSaveEvent: 'reloadListing',
                  }
                })
              } else {
                navigationFn('New', {
                  moduleName: item.moduleName,
                  id: item.moduleId,
                  viewOnly: true,
                });
              }
            } : (item.moduleName && !item.moduleId) ? () => {
              //Alert.alert("Error", "This record is not linked to any module.");
              if (Platform.OS === 'web') {
                const params = {
                  moduleName: item.moduleName,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  quickFilterKey: item.quickFilterKey,
                  quickFilterValue: item.quickFilterValue,
                  selectedFilters: item.selectedFilters,
                  groupBy: item.groupBy,
                }
                let paramsString = "";
                if (params) {
                  paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                }
                let path = "report/" + item.moduleName;
                navigate("/" + path + paramsString);
              } else {
                navigationFn('report', {
                  moduleName: item.moduleName,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  quickFilterKey: item.quickFilterKey,
                  quickFilterValue: item.quickFilterValue,
                  selectedFilters: item.selectedFilters,
                  groupBy: item.groupBy,
                  viewOnly: true,
                });
              }
            } : null}>

              <View marginB-10 row spread style={{ width: '100%' }}>
                {columns[0] ? <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                  {(item.image_urls && item.image_urls[0]) ?

                    <Image source={{
                      uri: item.image_urls[0]?.url ? item.image_urls[0]?.url.replace("//uploads", "/uploads") : item.image_urls[0]
                    }} style={{ width: 60, height: 60, resizeMode: 'cover', borderRadius: 10 }} /> :


                    <>
                      {columns[0].type == "datetime" || columns[0].type == "date" ? <>
                        <Text style={{ fontSize: 24, color: 'white' }}>{moment(getFieldValue(item, columns[0].field, columns[0], true)).format('DD')}</Text>
                        <Text style={{ fontSize: 12, color: 'white' }}>{moment(getFieldValue(item, columns[0].field, columns[0], true)).format('ddd')}</Text>
                      </> :
                        <>
                          {columns.find(c => c.type == "datetime" || c.type == "date") ? <>
                            <Text style={{ fontSize: 24, color: 'white' }}>{moment(getFieldValue(item, columns.find(c => c.type == "datetime" || c.type == "date").field, columns[0], true)).format('DD')}</Text>
                            <Text style={{ fontSize: 12, color: 'white' }}>{moment(getFieldValue(item, columns.find(c => c.type == "datetime" || c.type == "date").field, columns[0], true)).format('ddd')}</Text>
                          </> :
                            <Text bold style={{ color: 'white', fontSize: 20 }}>
                              {getFieldValue(item, columns[0].field, columns[0])[0] || ""}
                            </Text>
                          }</>}
                    </>}
                </View> : null}

                <View flex marginL-10>
                  <View row>
                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                      {columns[0] ? getFieldValue(item, columns[0].field, columns[0]) : ""}
                    </Text>
                    <View marginL-10>
                      {item.approvalStatus == 'Pending' && <Text style={{ color: '#ffc93a' }}>(Pending)</Text>}
                      {item.approvalStatus == 'Rejected' && <Text style={{ color: '#cc1010' }}>(Rejected)</Text>}
                    </View>
                  </View>

                  <View>
                    <View flex>
                      {columns.length > 2 ? <View row marginB-6 flex>
                        <View>
                          <Text lightCardTitle>{columns[1] ? columns[1].label : ""}: </Text>
                        </View>
                        <View flex>
                          {valueText(columns[1])}
                        </View>
                      </View> : null}
                      {columns.length > 3 ? <View row marginB-6 flex>
                        <View>
                          <Text lightCardTitle>{columns[2] ? columns[2].label : ""}: </Text>
                        </View>
                        <View flex>
                          {valueText(columns[2])}
                        </View>
                      </View> : null}
                    </View>
                  </View>
                </View>
              </View>

              {columns.length > 4 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
              {columns.length > 4 ? <View row spread style={{ width: '100%' }}>
                {columns.length > 4 ? <View row flex>
                  <View>
                    <Text lightCardTitle>{columns[3] ? columns[3].label : ""}: </Text>
                  </View>
                  <View flex>
                    {valueText(columns[3])}
                  </View>
                </View> : null}
                {columns.length > 5 ? <View row flex>
                  <View>
                    <Text lightCardTitle>{columns[4] ? columns[4].label : ""}: </Text>
                  </View>
                  <View flex>
                    {valueText(columns[4])}
                  </View>
                </View> : null}
              </View> : null}

              {columns.length > 6 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
              {columns.length > 6 ? <View row spread style={{ width: '100%' }}>
                {columns.length > 6 ? <View row flex>
                  <View>
                    <Text lightCardTitle>{columns[5] ? columns[5].label : ""}: </Text>
                  </View>
                  <View flex>
                    {valueText(columns[5])}
                  </View>
                </View> : null}
                {columns.length > 7 ? <View row flex>
                  <View>
                    <Text lightCardTitle>{columns[6] ? columns[6].label : ""}: </Text>
                  </View>
                  <View flex>
                    {valueText(columns[6])}
                  </View>
                </View> : null}
              </View> : null}

              {columns.length > 8 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
              {columns.length > 8 ? <View row spread style={{ width: '100%' }}>
                {columns.length > 8 ? <View row flex>
                  <View>
                    <Text lightCardTitle>{columns[7] ? columns[7].label : ""}: </Text>
                  </View>
                  <View flex>
                    {valueText(columns[7])}
                  </View>
                </View> : null}
                {columns.length > 9 ? <View row flex>
                  <View>
                    <Text lightCardTitle>{columns[8] ? columns[8].label : ""}: </Text>
                  </View>
                  <View flex>
                    {valueText(columns[8])}
                  </View>
                </View> : null}
              </View> : null}

            </TouchableOpacity>
            {actionFunctions(item).length > 0 ? <TouchableOpacity>
              <SidebarStatus color={"#397CF2"}
                text={item.status}
                actionFunctions={actionFunctions} item={item} radius={10} />
            </TouchableOpacity> : null}
          </Card>

          function valueText(column) {
            const value = getFieldValue(item, column.field);

            // if (column.type == "moduleLink") {
            //   return <TouchableOpacity onPress={() => {

            //   }}>
            //     <Text
            //       monoTableBody={item.boldRow ? false : mono}
            //       monoTableHeading={item.boldRow}
            //       style={[
            //         column.customCellStyle,
            //         { maxWidth: '100%' },
            //         { color: '#007bff', textDecorationLine: 'underline' }
            //       ]} ellipsizeMode={'tail'} numberOfLines={1}>
            //       {value}
            //     </Text>
            //   </TouchableOpacity>;
            // } else if (column.type == "reportLink") {
            //   return <TouchableOpacity onPress={() => {

            //   }}>
            //     <Text
            //       monoTableBody={item.boldRow ? false : mono}
            //       monoTableHeading={item.boldRow}
            //       style={[
            //         column.customCellStyle,
            //         { maxWidth: '100%' },
            //         { color: '#007bff', textDecorationLine: 'underline' }
            //       ]} ellipsizeMode={'tail'} numberOfLines={1}>
            //       {value}
            //     </Text>
            //   </TouchableOpacity>;
            // }

            return <Text darkCardTitle numberOfLines={1} ellipsizeMode={'tail'}>{column ? getFieldValue(item, column.field, column) : ""}</Text>;
          }
        }}
        keyExtractor={(item, index) => item._id || index.toString()}
        onRefresh={() => {
          if (onRefresh) {
            onRefresh();
          }
        }}
        refreshing={loading}
        onEndReached={() => {
          if (onQueryParams) {
            onQueryParams({
              pageSize: pageSize + 10,
            })
          }
        }}
        ListEmptyComponent={() => <View flex centerV centerH style={Platform.OS == 'web' ? {
          height:
            Dimensions.get('window').height - 200
        } : {
          height: 80
        }}>
          <Text darkCardTitle>No data found</Text>
        </View>}
      />
    </View>}

    <CustomModal
      drawer
      width={450}
      title="Customize Fields"
      visible={cfModalVisible}
      onClose={() => {
        setCfModalVisible(false);
        saveColumns && saveColumns(allColumnsSorted);
      }}
    >
      <View flex useSafeArea padding-10>
        <SortableList
          data={allColumns}
          renderItem={renderItem}
          keyExtractor={(item, index) => `draggable-item-${index}`}
          onOrderChange={(newOrderedData) => {
            setAllColumnsSorted(newOrderedData);
          }}
          scale={1.02}
        />
      </View>
    </CustomModal>

    <CustomModal
      drawer
      visible={editFieldModalVisible}
      onClose={() => setEditFieldModalVisible(false)}
    >
      <View useSafeArea>
        <ScrollView style={{
          height: Dimensions.get('window').height - 100
        }}>
          <View padding-20>
            {columns.map((column, index) => {
              column.key = column.id ? column.id : column.accessor;

              return <View key={index.toString()}>
                {Label({ label: column.label })}

                {column.type === "text" && <TextField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "number" && <NumberField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "select" && <SelectField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "duration" && <DurationField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} />}

                {column.type === "autocomplete" && <AutoCompleteField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} />}

                {column.type === "date" && <DateField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "time" && <TimeField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "checkbox" && <CheckboxField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "switch" && <SwitchField field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

                {column.type === "tags_text_area" && <SerialNumberTextArea field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} />}

                {column.type === "custom" && <column.component field={{ ...column, independent: true }} useForm={useForm} tKey={tableKey} tIndex={editFieldModalIndex} viewOnly={!column.editable} />}

              </View>
            })}
            <View>

            </View>
            <PrimaryButton label="Close" onPress={() => setEditFieldModalVisible(false)} />
          </View>
        </ScrollView>
      </View>
    </CustomModal>
  </>
  );

  function actionsFn(row, cell, index) {
    return (
      <div className="td sticky__right action_col" {...cell.getCellProps(cellProps)} onClick={(e) => e.stopPropagation()}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}>
          {actionFunctions ? (
            <div className="actions-container">
              {actionFunctions && actionFunctions(row.original).length >= 1 ? (
                <>
                  {(actionFunctions && actionFunctions(row.original).length == 1 && actionFunctions(row.original)[0].label == "Delete") ? <>
                    <div style={{
                      cursor: 'pointer',
                      height: 40,
                      width: 40,
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: 'center',
                    }} onClick={() => {
                      actionFunctions(row.original)[0].fn({
                        data,
                        updateData,
                        row: row.original,
                        row_index: index
                      })
                    }}>
                      {/* <Icon name='trash-2-outline' width={16} height={16} fill='#53535f' /> */}
                      <img src={"https://cdn-icons-png.flaticon.com/512/11847/11847093.png"} style={{ width: 20, height: 20 }} />
                    </div>
                  </> :
                    <div className="dropdown">
                      <div className="pointer" onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                          menu={{
                            items: actionFunctions(row.original).map((action) => ({
                              label: action.label, onClick: () => action.fn({
                                data,
                                updateData,
                                row: row.original,
                                row_index: index
                              })
                            }))
                          }}
                        >
                          <View left>
                            <Button avoidMinWidth borderRadius={4}
                              outline
                              outlineColor="#edf2f9"
                              style={{ boxShadow: '0 2px 0 rgba(0,0,0, 0.015)' }}>
                              {/* more-vertical-outline */}
                              <Icon name='more-vertical-outline' width={16} height={16} fill='#53535f' />
                            </Button>
                          </View>
                        </Dropdown>
                      </div>
                    </div>}
                </>) : null}
            </div>
          ) : null}
        </div>

      </div>
    );
  }

  function head() {
    return <div style={{ display: 'flex', position: 'sticky', top: 0, zIndex: 9, minWidth: '100%', background: 'white' }}>
      {headerGroups.map((headerGroup, index) => (
        <div
          key={index.toString()}
          {...headerGroup.getHeaderGroupProps({
            style: { background: 'transparent' }
          })}
          className="tr heading"
        >
          {headerGroup.headers.map((column, colIndex) => {

            if (column.id === 'actions') {
              return <div {...column.getHeaderProps(headerProps)} className={`th sticky__right action_col`}>
                {/*  */}
                {cfAdd && <View style={{ cursor: 'pointer' }} onClick={() => setCfModalVisible(true)}>
                  <Image assetName="drag" style={{ width: 20, height: 20 }} />
                </View>}
              </div>
            }

            return <div {...column.getHeaderProps(headerProps)} className={`th ${colIndex === 0 ? `sticky__left ${showCheckbox ? 'left_with_checkbox' : ''}` : ''} ${column.grow ? 'column_grow' : ''} ${onQueryParams ? 'pointer' : ''}`} onClick={() => {
              if (sortColumn === column.id) {
                if (sortDirection === 'asc') {
                  onQueryParams && onQueryParams({
                    sortColumn: column.id,
                    sortDirection: 'desc',
                  });
                } else {
                  onQueryParams && onQueryParams({
                    sortColumn: null,
                    sortDirection: null,
                  });
                }
              } else {
                onQueryParams && onQueryParams({
                  sortColumn: column.id,
                  sortDirection: 'asc',
                });
              }
            }}>

              {(showCheckbox && colIndex === 0) && <Checkbox
                checked={selectedRows.size === rows.length && rows.length > 0}
                indeterminate={selectedRows.size > 0 && selectedRows.size < rows.length}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelectAll(e.target.checked)
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              }

              {mono ? <Text monoTableHeading={mono}>
                {column.render('Header')}
              </Text> : column.render('Header')}

              {onQueryParams ? <div style={{
                position: 'absolute',
                top: 10,
                right: 12,
              }}>
                {sortColumn === column.id ? (
                  sortDirection === 'asc' ? (
                    <Icon name="arrow-up-outline" width={16} height={16} fill={"rgb(80, 92, 101)"} />
                  ) : (
                    <Icon name="arrow-down-outline" width={16} height={16} fill={"rgb(80, 92, 101)"} />
                  )
                ) : null}
              </div> : null}

              {/* Use column.getResizerProps to hook up the events correctly */}
              {column.canResize && (
                <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing ? 'isResizing' : ''
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </div>;
          })}
        </div>
      ))}
    </div>;
  }
};

export default DynamicTable;

function editCardFn(setEditFieldModalData, item, setEditFieldModalIndex, index, setEditFieldModalVisible) {
  setEditFieldModalData(item);
  setEditFieldModalIndex(index);
  setEditFieldModalVisible(true);
}

