import React, { useEffect } from "react";
import { View, Card, Text, TouchableOpacity, Chip } from "react-native-ui-lib";
import { Col, CustomAutoComplete, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../mycomponents/DynamicForm";
import { Video, ResizeMode } from 'expo-av';
import { Dimensions, Platform, ScrollView } from "react-native";
import Svg, { Rect, Path } from "react-native-svg";
import { CustomSelectItemForContacts } from "../accountancy/transaction";
import { api } from "../../services/api";
import moment from "moment";
import ListingScreen from "../../screens/MainScreens/CRUD/ListingScreen";
import { Select } from "antd";
import { App } from 'antd';

export const subscriptionfields = (response) => {

  const SubscriptionComponent = ({ field, useForm, tKey, tIndex }) => {
    const contact_id = useForm((state) => state.formObject['contact_id']);
    const subscription = useForm((state) => state.formObject);

    const { message, modal } = App.useApp();

    return <>
      <View flex row style={{
        flexWrap: 'wrap'
      }}>
        <View marginB-10 row spread style={{ width: '100%' }}>

          <View flex>
            <View row spread>
              <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                {subscription.item.name}
              </Text>
              {moment(subscription.end_date).isBefore(moment()) && <View style={{
                backgroundColor: '#cb1721',
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 5,
              }}>
                <Text style={{ color: 'white' }}>Expired</Text>
              </View>}
            </View>


            {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
              <View
                style={{
                  borderStyle: 'dashed',
                  borderWidth: 1,
                  borderColor: '#d9d9d9',
                  margin: -2,
                  marginTop: 5,
                }}>
                <View style={{ height: 20, width: '100%' }} />
              </View>
            </View> : <View style={{
              borderBottomColor: '#d9d9d9',
              borderBottomWidth: 1,
              width: '100%',
              marginBottom: 0,
              marginTop: 5,
              borderStyle: 'dashed',
            }} />}

            <View flex row marginT-10>
              <View row marginB-6 flex centerV>
                <View>
                  <Text lightCardTitle>{"Start Date"}: </Text>
                </View>
                <View flex>
                  <Text>{moment(subscription.start_date).format('DD MMM YYYY')}</Text>
                </View>
              </View>
              <View row marginB-6 flex>
                <View>
                  <Text lightCardTitle>{"Expiry Date"}: </Text>
                </View>
                <View flex>
                  <Text>{moment(subscription.end_date).format('DD MMM YYYY')}</Text>
                </View>
              </View>
            </View>

            {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
              <View
                style={{
                  borderStyle: 'dashed',
                  borderWidth: 1,
                  borderColor: '#d9d9d9',
                  margin: -2,
                  marginTop: 5,
                }}>
                <View style={{ height: 20, width: '100%' }} />
              </View>
            </View> : <View style={{
              borderBottomColor: '#d9d9d9',
              borderBottomWidth: 1,
              width: '100%',
              marginBottom: 0,
              marginTop: 5,
              borderStyle: 'dashed',
            }} />}

            {subscription.subscription_item_usage.map((item, j) => (<View key={j} marginT-10>
              <View flex>
                <View row marginB-6 flex spread centerV>
                  <View>
                    <Text lightCardTitle>
                      {item.item?.name}
                    </Text>
                  </View>
                  <View flexG row centerV right>
                    <View marginR-5 style={{
                      width: 80,
                    }}>
                      <CustomNumberInput
                        type="number"
                        placeholder="PRP"
                        visible={true}
                        width="100%"
                        value={item.used_quantity}
                        onChange={(value) => {
                          const newSubscriptions = [...subscriptions];
                          const newSubscription = { ...newSubscriptions[i] };
                          const newSubscriptionItemUsage = [...newSubscription.subscription_item_usage];
                          const newSubscriptionItemUsageItem = { ...newSubscriptionItemUsage[j] };
                          newSubscriptionItemUsageItem.used_quantity = value;
                          newSubscriptionItemUsage[j] = newSubscriptionItemUsageItem;
                          newSubscription.subscription_item_usage = newSubscriptionItemUsage;
                          newSubscriptions[i] = newSubscription;
                          setSubscriptions(newSubscriptions);
                        }}
                      />
                    </View>
                    <Text>used out of </Text> <Text style={{ fontFamily: 'SourceSansProBold' }}>{item.quantity}</Text>
                  </View>
                </View>
              </View>
            </View>))}

            <View marginT-10>
              <PrimaryButton
                label="Update"
                onPress={async () => {
                  modal.confirm({
                    title: 'Are you sure you want to update the usage?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      const response = await api.customRoute('Invoices/action', {
                        action: 'updateSubscriptionUsage',
                        _id: subscription.invoice_id,
                        newQuantityArray: subscriptions[i].subscription_item_usage
                      });

                      if (response.success) {
                        message.success('Updated successfully');
                      } else {
                        message.error('Error updating');
                      }
                    },
                    onCancel: () => {
                    }
                  });
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </>
  };

  const formFields = [
    {
      type: 'divider',
      heading: 'Subscription Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Contact',
      key: 'contact_id',
      type: 'select',
      placeholder: 'Select the contact',
      visible: true,
      width: '25%',
      options: response.Contacts || [],
      creatableAction: (props) => {
        const params = {
          ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
            is_default_values: true,
            phone: props.search,
            is_supplier: false,
          } : {})
        };
        OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
      },
      customSelectItem: CustomSelectItemForContacts,
      otherSearchKeys: ['phone', 'email'],
      autoFocus: true,
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        setObj({
          updateVisiblity: Math.random(),
        })
      }
    },
    {
      key: 'active_subscriptions',
      type: 'custom',
      visible: true,
      width: '100%',
      component: SubscriptionComponent,
    },
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const subscriptionMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const subscriptionMain = {
  fields: subscriptionfields,
  mobileCard: null
}